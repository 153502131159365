@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "font-Serif";
}

@layer components {
  /* components style */
  .NavBarStyle {
    @apply bg-slate-100 absolute w-full  right-0 px-12 py-3 md:hidden max-md:top-[60px];
  }

  .ButtonTextStyle {
    @apply text-RSredDeep tracking-[2px] font-medium text-nowrap  text-2xl px-[75px] BaseFamily py-5 hover:bg-RSredLight hover:rounded-full hover:text-white;
  }
  .ButtonStyle {
    @apply bg-contain bg-no-repeat bg-center flex justify-center w-[260px] p-1 max-lg:scale-90 max-md:scale-75;
  }
  .MemberDisplaySetup {
    @apply flex justify-around px-[15%] pt-24 max-lg:px-[10%] max-md:px-[7%] max-md:pt-20;
  }
  .swiperStyle {
    @apply scale-[0.63] select-none;
  }

  /* Font style */
  .BaseFamily {
    @apply font-Serif;
  }
  .BannerTitle {
    @apply tracking-[10px] font-bold text-white text-5xl duration-300 drop-shadow-xl BaseFamily max-md:text-4xl;
  }
  .SectionTitle {
    @apply tracking-wide text-white text-[26px] font-medium BaseFamily max-lg:text-2xl  max-md:text-lg;
  }
  .MediumTitle {
    @apply tracking-[4px] text-xl font-medium BaseFamily max-lg:text-lg max-md:text-sm max-md:tracking-[3px];
  }
  .SmallTitle {
    @apply tracking-[4px] text-white text-lg font-medium BaseFamily max-lg:text-sm max-lg:text-nowrap max-lg:tracking-[1px] max-md:tracking-[2px] max-sm:text-xs;
  }
  .NavText {
    @apply tracking-[2px] text-lg text-RSblack font-normal hover:text-gray-400 BaseFamily;
  }
  .PoetryText {
    @apply tracking-[2px] text-base text-white BaseFamily max-lg:tracking-[1.5px] max-lg:text-[15px];
  }
  .DesText {
    @apply tracking-[3px] text-base font-normal text-white BaseFamily max-lg:text-[15px];
  }
  .MemberText {
    @apply tracking-[2px] text-[22px] text-white BaseFamily max-lg:text-[18px] max-md:text-base;
  }
  .JobText {
    @apply tracking-[2px] text-lg text-RSgoldDeep BaseFamily max-md:text-sm;
  }
  .footerText {
    @apply tracking-[1px] text-base text-white BaseFamily;
  }
  .TsaiYin {
    @apply tracking-[5px] text-2xl text-[#949494] font-medium BaseFamily max-lg:text-xl;
  }
  .InterviewTitle {
    @apply tracking-[5px] leading-10 text-white text-2xl font-bold BaseFamily max-lg:text-xl max-lg:tracking-[3px] max-sm:text-lg;
  }
  .InterviewParagraph {
    @apply tracking-[2.5px] text-white text-base font-light BaseFamily leading-7;
  }
}

@layer base {
  * {
    @apply selection:bg-RSgoldDeep duration-300;
  }
  ,
  p,
  html,
  body,
  div,
  span,
  applet,
  object,
  button,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img {
    @apply text-base font-Serif text-white select-none;
  }
}
